import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Auth, AuthResponse } from '../_models/auth';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../_models/core';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private httpClient: HttpClient) {}

  login(body: Auth): Observable<AuthResponse> {
    return this.httpClient.post<AuthResponse>(
      `${environment.apiUrl}login`,
      body
    );
  }
  getCurrency(): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(
      `${environment.apiUrl}getCurrency`
    );
  }
  getCurrencybalanceWith(value): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(
      `${environment.apiUrl}getParCurrencyBalance`,value
    );
  }
  withdrawWallet(value): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(
      `${environment.apiUrl}withdrawWallet`,value
    );
  }
  getSiteSettings(): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(
      `${environment.apiUrl}getSiteSettings`
    );
  }
  UpdateSiteSettings(body): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(
      `${environment.apiUrl}UpdateSiteSettings`,
      body
    );
  }
  withdrawOtp(body): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(
      `${environment.apiUrl}withdrawOtp`,
      body
    );
  }
  UploadImage(body: any, sizeFile,type): Observable<AuthResponse> {
    return this.httpClient.post<AuthResponse>(
      `${environment.apiUrl}fileUpload?sizeFile=${sizeFile}&&type=${type}`,
      body
    );
  }
  getCurrencyBalance(): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(
      `${environment.apiUrl}getCurrencyBalance`
    );
  }
  getMyProfile(): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(
      `${environment.apiUrl}getMyProfile`
    );
  }
  getDepositAddress(): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(
      `${environment.apiUrl}getDepositAddress`
    );
  }
  getAdminWithdraw(): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(
      `${environment.apiUrl}getAdminWithdraw`
    );
  }
  getProfit(): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(
      `${environment.apiUrl}getProfit`
    );
  }
  updateMyProfile(body): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(
      `${environment.apiUrl}updateMyProfile`,
      body
    );
  }
  changePassword(body): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(
      `${environment.apiUrl}changePassword`,
      body
    );
  }
}
